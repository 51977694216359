var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commune-profile pb-xxl"},[_c('header',[_c('breadcrumb',{attrs:{"content":_vm.breadcrumbProfile}}),_c('div',{staticClass:"page-header wrapper"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"mb-m",domProps:{"innerHTML":_vm._s(_vm.i18n.communeProfile.title)}})])])],1),_c('div',{staticClass:"wrapper"},[(_vm.i18n.communeProfile.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.communeProfile.optionHtml.htmltext)}}):_vm._e(),_c('form',{ref:"form",staticClass:"form row col-xxxs col-m-14",on:{"input":_vm.onInput,"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('user-form',{staticClass:"col-xxxs-20 row",attrs:{"model":_vm.inputsValues,"role":"view"},on:{"error":_vm.handleError}}),(_vm.globalI18n)?_c('span',{staticClass:"small col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.globalI18n.requiredFields)+" ")]):_vm._e(),(_vm.message)?_c('feedback-message',{attrs:{"content":{ type: _vm.hasError ? 'error' : 'success', htmltext: _vm.message }}}):_vm._e(),_c('CustomLink',{staticClass:"submit",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
          label: _vm.i18n.userGestion.valider,
          tag: 'button',
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
        },"disabled":_vm.disabledCondition,"modifiers":['btn', 'arrow'],"type":'submit'}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }