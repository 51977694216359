














































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import userAccountRequest from '@/composables/userAccountRequest'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import UserForm from '@/components/commune/components/UserFormProfile.vue'
import VInput from '@/components/form/VInput.vue'
import auth from '@/composables/auth'
import Breadcrumb from '@/components/Breadcrumb.vue'
import lodash from 'lodash'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'commune-profile',
  components: {
    FeedbackMessage,
    UserForm,
    VInput,
    Breadcrumb,
    CustomLink,
  },

  setup(_props, ctx) {
    const isValid = ref(false)
    const isLoading = ref(false)
    const hasError = ref(false)
    const message = ref('')
    const form = ref<HTMLFormElement>()
    const { i18n } = useState('commune', ['i18n'])
    const breadcrumbProfile = [
      {
        label: i18n.value.communeProfile.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const { $logger } = ctx.root
    const { user, chrome } = useState(['user', 'chrome'])
    const { fetchI18nOnly } = useActions('my', ['fetchI18nOnly'])
    const { UPDATE_USER } = useMutations('user', ['UPDATE_USER'])
    const orignalUser = {
      Firstname: user.value.firstname,
      Lastname: user.value.lastname,
      Phone: user.value.contact.phone,
      PhoneFixe: user.value.contact.phoneFixe,
      ContactEmail: user.value.contact.contactEmail,
      Email: user.value.email,
      Adresse: {
        Cdpostal:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.cdpostal
            : '',
        Localite:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.localite
            : '',
        Rue:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.rue
            : '',
        NumRue:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.numRue
            : '',
        CodePays: 'BE',
      },
      Commune: {
        Departement: user.value.commune.departement,
        Fonction: user.value.commune.fonction,
        Localite: user.value.commune.localite,
        Roles: user.value.commune.roles,
      },
    }

    const inputsValues = reactive({
      Firstname: user.value.firstname,
      Lastname: user.value.lastname,
      Phone: user.value.contact.phone,
      PhoneFixe: user.value.contact.phoneFixe,
      ContactEmail: user.value.contact.contactEmail,
      Email: user.value.email,
      Adresse: {
        Cdpostal:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.cdpostal
            : '',
        Localite:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.localite
            : '',
        Rue:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.rue
            : '',
        NumRue:
          // eslint-disable-next-line no-negated-condition
          user.value.contact.adresse !== null
            ? user.value.contact.adresse.numRue
            : '',
        CodePays: 'BE',
      },
      Commune: {
        Departement: user.value.commune.departement,
        Fonction: user.value.commune.fonction,
        Localite: user.value.commune.localite,
        Roles: user.value.commune.roles,
      },
    })

    onMounted(() => {
      fetchI18nOnly()
      onInput()
    })

    const phoneError = ref(false)
    const landlineError = ref(false)
    const handleError = value => {
      // phoneError.value = reactive(value)
    }
    ctx.root.$on('error:phone', value => (phoneError.value = value))

    ctx.root.$on('error:landline', value => (landlineError.value = value))

    const onInput = () => {
      isValid.value = !phoneError.value
    }
    /* eslint-disable */
    const disabledCondition = computed(() => {

      if (!lodash.isEqual(orignalUser, inputsValues) && phoneError.value && landlineError.value) {
        isValid.value = true
        return false
      }

      return true
    })

    const isValidPhone = () => {
      if (user.value.contact.phone !== inputsValues.Phone) {
        return false
      }

      return true
    }

    const onSubmit = async () => {
      form.value!.reportValidity()
      $logger.debug('USER UPDATE')
      message.value = ''

      if (!isValid.value) {
        message.value = 'Erreur dans le numéro de télephone fixe ou mobile'
        hasError.value = true

        return
      }

      isLoading.value = true
      hasError.value = false

      const {error} = await userAccountRequest('users', 'patch', inputsValues)

      if (error) {
        $logger.error('USER UPDATE', error)
        message.value = error?.response?.data?.message
        hasError.value = true
      } else {
        await auth.refresh()
        message.value = 'Vos données ont été mises à jour'
        const userNewData = {
          contact: {
            phone: inputsValues.Phone,
            phoneFixe: inputsValues.PhoneFixe !== '' ? inputsValues.PhoneFixe : orignalUser.PhoneFixe,
            contactEmail: inputsValues.ContactEmail,
            adresse: {
              cdpostal: inputsValues.Adresse.Cdpostal,
              localite: inputsValues.Adresse.Localite,
              numRue: inputsValues.Adresse.NumRue,
              rue: inputsValues.Adresse.Rue,
            },
          },
          commune: user.value.commune,
          validPhone: isValidPhone(),
          firstname: inputsValues.Firstname,
          lastname: inputsValues.Lastname,
        }

        UPDATE_USER(userNewData)
      }

      isLoading.value = false
    }

    const globalI18n = computed(() => chrome.value.data.i18n)

    return {
      handleError,
      form,
      globalI18n,
      hasError,
      i18n,
      inputsValues,
      isLoading,
      isValid,
      message,
      onInput,
      onSubmit,
      disabledCondition,
      phoneError,
      breadcrumbProfile,
    }
  },
})
