













































































































































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import VSelect from '@/components/form/VSelectGood.vue'
import VInput from '@/components/form/VInput.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import GModal from '@/components/g/Modal.vue'
import PhoneValidForm from '@/components/my/PhoneValidForm.vue'
import userAccountRequest from '@/composables/userAccountRequest'

import { Address, fetchArea, getStreets } from '@/composables/address'

export default defineComponent({
  name: 'my-user-form',
  components: { FeedbackMessage, VInput, VSelect, GModal, PhoneValidForm },
  props: {
    model: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    role: {
      type: String as () => 'view' | 'signup',
      required: true,
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const communeState = {
      ...useState('commune', ['i18n']),
    }
    const communeI18n = communeState.i18n
    const { user } = useState(['user'])

    const address = ref<Address[]>([])
    const streets = ref<string[]>([])

    const firstSendSms = ref(true)

    const defaultCode = '+32'
    const countryCodes = [
      { value: '32', label: '+32' },
      { value: '33', label: '+33' },
      { value: '352', label: '+352' },
      { value: '49', label: '+49' },
      { value: '34', label: '+34' },
      { value: '39', label: '+39' },
      { value: '44', label: '+44' },
      { value: '41', label: '+41' },
      { value: '48', label: '+48' },
      { value: '212', label: '+212' },
      { value: '1', label: '+1' },
      { value: '351', label: '+351' },
      { value: '31', label: '+31' },
    ]
    // Check for country code in incoming data
    const countryCode = computed(() => {
      let code = ''

      if (!props.model || !props.model.Phone) {
        return code
      }

      countryCodes.forEach(item => {
        // Check for country code starting with +|00
        const reg = new RegExp(`^(\\+|00)${item.value}`, 'g')
        const result = props.model.Phone.match(reg)

        if (result) {
          ;[code] = result
        }
      })

      return code
    })

    // Split country code and actual number
    /* eslint-disable */
    const defaultPhoneNumber = props.model.Phone
        ? ref(
            countryCode.value.length
                ? props.model.Phone.substring(countryCode.value.length)
                : props.model.Phone.replace(/^0/, '')
        )
        : ref('')
    /* eslint-enable */
    const phoneNumber = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          defaultPhoneNumber.value,
          '###/######'
        )
      },
      set(val: string) {
        defaultPhoneNumber.value = val.replace(/^0|\//, '')
      },
    })
    const phoneError = computed(() => {
      /* eslint-disable */
      const isValid =
          /^(1[0-69]|[23][2-8]|4[23]|4[0-9]\d|5\d|6[01345789]|7[01689]|8[0-79]|9[012])\d{6}$/.test(
              defaultPhoneNumber.value
          )
      /* eslint-enable */
      ctx.root.$emit('error:phone', isValid)

      return isValid
    })

    // LandlinePhone
    /* eslint-disable */
    const defaultLandline = props.model.PhoneFixe
        ? ref(
            countryCode.value.length
                ? props.model.PhoneFixe.substring(countryCode.value.length)
                : props.model.PhoneFixe.replace(/^0/, '')
        )
        : ref('')

    const landlineNumber = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
            defaultLandline.value,
            '##########'
        )
      },
      set(val: string) {
        defaultLandline.value = val.replace(/^0|\//, '')
      },
    })

    const landlineError = computed(() => {
      const isValid =
          /^(1[0-69]|[23][2-8]|4[23]|4[0-9]\d|5\d|6[01345789]|7[01689]|8[0-79]|9[012])\d{6}$/.test(
              defaultLandline.value
          )
      if (defaultLandline.value.length > 0) {
        ctx.root.$emit('error:landline', isValid)
      } else {
        ctx.root.$emit('error:landline', true)
      }

      return isValid
    })

    watch([countryCode, defaultPhoneNumber], ([newCode, newNumber]) => {
      if (newCode) {
        props.model.Phone = newCode + defaultPhoneNumber.value
      }

      if (newNumber) {
        const number = countryCode.value.length
            ? countryCode.value + newNumber
            : defaultCode + newNumber

        props.model.Phone = number
      }
    })

    watch([countryCode, defaultLandline], ([newCode, newNumber]) => {
      if (newCode) {
        props.model.PhoneFixe = newCode + defaultLandline.value
      }

      if (newNumber) {
        const number = countryCode.value.length
            ? countryCode.value + newNumber
            : defaultCode + newNumber

        props.model.PhoneFixe = number
      }
    })

    watch(
        () => props.model.Adresse.Cdpostal,
        cdpostal => {
          streets.value = []
          props.model.Adresse.Localite = null
          props.model.Adresse.Rue = null
          props.model.Adresse.NumRue = null

          if (cdpostal.length === 4) {
            fetchArea(cdpostal).then(res => {
              props.model.Adresse.Localite = res.localite
              address.value = res.address
              streets.value = getStreets(
                  address.value,
                  props.model.Adresse.Localite
              )
            })
          }
        }
    )

    watch(
        () => props.model.Adresse.Localite,
        _ => {
          streets.value = getStreets(address.value, props.model.Adresse.Localite)
          props.model.Adresse.Rue = null
          props.model.Adresse.NumRue = null
        }
    )

    watch(
        () => props.model.Adresse.Rue,
        _ => {
          props.model.Adresse.NumRue = null
        }
    )

    const sendSms = async () => {
      firstSendSms.value = true
      const {error} = await userAccountRequest(
          'users/activate/sendSms',
          'post',
          {
            Type: 'sendSms',
            Phone: `+32${phoneNumber.value.replace(/^0|\//, '')}`,
          }
      )
      setTimeout(() => {
        firstSendSms.value = false
      }, 60000)
    }

    const formatPhone = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
            defaultPhoneNumber.value,
            '+32 ### ## ## ##'
        )
      },
      set(val: string) {
        defaultPhoneNumber.value = val.replace(/^0|\//, '')
      },
    })

    return {
      countryCode,
      countryCodes,
      streets,
      i18n,
      communeI18n,
      user,
      defaultLandline,
      landlineError,
      landlineNumber,
      phoneNumber,
      defaultPhoneNumber,
      phoneError,
      showModal: false,
      PhoneValidForm,
      firstSendSms,
      sendSms,
      formatPhone,
    }
  },
})
